export const DOCUMENT_CLASS = {
  documentId: String,
  status: String,
  documentInfo: {
    documentType: String,
    documentEntityType: String,
    documentTitle: String,
    uploadDate: Date,
    uploadBy: String,
  },
  documentUpload: {
    originalFilename: String,
    contentType: String,
    name: String,
    externalId: String,
  },
};

export const IMAGE_CLASS = {
  imageType: String,
  documentUpload: {
    externalId: String,
    size: Number,
  },
};

export const USER_REPORT_CLASS = {
  status: String,
  createdAt: Date,
  lastModified: Date,
  reportName: String,
  feedbackType: String,
  labelFeedbackType: String,
  feedbackQuestion: String,
  labelFeedbackQuestion: String,
  note: String,
  closeReport: Date,
  userCloseComment: String,
  startLitigation: Date,
  closeLitigation: Date,
  litigationReply: String,

  referentInfo: {
    companyId: String,
    companyName: String,
    vatNumber: String,
    personId: String,
    name: String,
    surname: String,
    taxCode: String,
  },
  reporterInfo: {
    companyId: String,
    companyName: String,
    vatNumber: String,
    personId: String,
    name: String,
    surname: String,
    taxCode: String,
  },
};

export const USER_ANALYSIS_CLASS = {
  analysisId: String,
  status: String,
  createdAt: Date,
  lastModified: Date,
  requestName: String,
  requestType: String,
  labelRequestType: String,
  requestProduct: String,
  labelRequestProduct: String,
  referentInfo: {
    companyId: String,
    companyName: String,
    vatNumber: String,
    personId: String,
    name: String,
    surname: String,
    taxCode: String,
  },
  ownerRequestInfo: {
    companyId: String,
    companyName: String,
    vatNumber: String,
    personId: String,
    name: String,
    surname: String,
    taxCode: String,
  },
};
